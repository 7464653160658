import React from 'react'
/** @jsxImportSource @emotion/react */

import {connect, useDispatch} from 'react-redux'


import {useNavigate} from 'react-router-dom'

import { 
  makeGetArtistTracks, 
  makeGetArtistFetchedAssociations 
} from '../../selectors'


import {show} from '../../apis'

import {convertSpotifyTracksToCkordFormat} from '../../helpers'

import { 
  pause, 
  addTracksToArtist,
  getQueueAndPlayTracks,
  queue,
  enqueue,
  showAlert
} from '../../actions'
import { getImageOfSize } from '../../utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArtistCardButtons from './ArtistCardButtons'
import Typography from '@mui/material/Typography'
import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { Image } from '../generics'


import artistCardStyles from '../../styles/cards/desktop/artistCardStyles'
import artistCardMobileStyles from '../../styles/cards/mobile/artistCardMobileStyles'



const ArtistCard = props => {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const { 
    artist,
    tracks,
    isLoaded,
    isPlaying,
    pause,
    getQueueAndPlayTracks,
    addTracksToArtist,
    queue,
    enqueue,
    showAlert,
  } = props
  
  const { name, trackCount, image, links} = artist.attributes
  const imageURL = getImageOfSize(artist.attributes, 'small') || image
  const href = process.env.REACT_APP_BASE_URL + '/artists/' + artist.id


  const styles = isExtraSmallScreen ? artistCardMobileStyles()
                                    : artistCardStyles()


  const icons = {
    play:  <PlayIcon  color='secondary' fontSize={ isExtraSmallScreen ? 'medium' : 'large' } />,
    pause: <PauseIcon color='secondary' fontSize={ isExtraSmallScreen ? 'medium' : 'large' } />
  }

  const icon = (isPlaying ? 'pause' : 'play')

  function handleClick(e) {
    e.preventDefault()
    e.stopPropagation()

    navigate('/artists/' + artist.id)
  }



  function handlePlayPress(e) {
    e.preventDefault()
    e.stopPropagation()

    if (icon === 'play') {
      if (isLoaded) {
        queue(tracks, artist.id, 'artist')
      } else { 
        fetchAndPlayAudio()
        fetchSpotifyTracks()
      }
    } else {
      pause()
    }
  }


  function fetchAndPlayAudio() {
    const url = '/artists/' + artist.id + '/tracks'

    getQueueAndPlayTracks(url, artist.id, 'artist').then(response => {
      if (response === 'error') {
        showAlert('Something went wrong loading this artist\'s tracks', 'error')
      }
    })
  }



  function fetchSpotifyTracks() {
    if (!links.spotify) return

    
    const spotifyArtistID = links.spotify.split('/').reverse()[0]

    if (!spotifyArtistID) return


    show('/services/spotify/artists/' + spotifyArtistID + '/tracks').then(response => {
      const data = response.data

      // Format the Spotify tracks to match the Ckord tracks
      const newTracks = convertSpotifyTracksToCkordFormat(data, artist.id, 'Artist', links.spotify)


      // Update the store
      dispatch({type: 'TRACKS_INDEX_SUCCESS', payload: {data: newTracks}})
      addTracksToArtist(artist.id, newTracks)

      // Add the tracks to the player queue
      enqueue(newTracks, artist.id, 'artist')
    })
    .catch(error => {})
  }



  return (
    <div css={ styles.card }>
      <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
        <div css={ styles.imageContainer } onClick={ handleClick }>
        { (trackCount > 0 || links.spotify) &&  
            <div css={ styles.iconContainer }>
              <div css={ styles.iconSubContainer }>
                <div css={ styles.iconCircle } onClick={ handlePlayPress }>
                  { icons[icon] }
                </div>
              </div>
            </div>
          }

          <Image css={ styles.image } src={ imageURL } alt='artist' />
        </div>
      </a>

        <div css={ styles.infoContainer } onClick={ handleClick }>
          <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
            <Typography css={ styles.title }>
              { name }
            </Typography>
            
            <Typography noWrap>
              Artist
            </Typography>
          </a>
        </div>

        <div css={ styles.buttons }>
          <ArtistCardButtons 
            artistId={ artist.id } 
            name={ name }
            imageURL={ imageURL }
          />
        </div>
    </div>
  )
}


const makeMapStateToProps = () => {
  const getArtistTracks = makeGetArtistTracks()
  const getFetchedAssociations = makeGetArtistFetchedAssociations()
  

  const mapStateToProps = (state, props) => {
    const id = props.artist.id
    const tracks = getArtistTracks(state, id)
    const isLoaded = getFetchedAssociations(state, id).includes('tracks')

    const callerID = state.player.callerID
    const isPlaying = state.player.isPlayRequested && callerID === id

    return {
      tracks: tracks,
      isLoaded: isLoaded,
      isPlaying: isPlaying
    }
  }

  return mapStateToProps
}


const actions = { 
  pause,
  addTracksToArtist,
  getQueueAndPlayTracks,
  queue,
  enqueue,
  showAlert
}

export default connect(makeMapStateToProps, actions)(ArtistCard)

