import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import ImageIcon from '@mui/icons-material/ImageOutlined'


const styles = {
  root: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 30%;
  `,
  icon: css`
    height: 100%;
    width: 100%;
    color: #979797;
    max-width: 80px;
  `
}


const DefaultPlaceholder = props => (
  <div css={styles.root} {...props}>
    <ImageIcon css={styles.icon}/>
  </div> 
)



const Image = React.forwardRef(({src, alt, placeholder, loading = 'eager', ...props}, ref) => {
  return (
    <>
      {src 
        ? <img src={src} alt={alt} loading={loading} ref={ref} {...props} /> 
        : Boolean(placeholder) ? placeholder : <DefaultPlaceholder {...props} />
      }
    </>
  )
})



export default React.memo(Image)