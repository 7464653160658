import React, { Suspense, useEffect, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import {Helmet} from 'react-helmet-async'
import smoothscroll from 'smoothscroll-polyfill'
import axios from 'axios'
import {
  updateLocationFilter,
  fetchAuthStatus, 
  fetchUserLocation,
  setUserLocation,
  fetchFavoritesOfType,
  fetchArtistRelationships,
  tryNextGeolocationMethod,
  initiateSearch
} from '../actions'

import {
  useUTMParams,
  useSearchVenues,
  useSearchPerformances,
  useSearchEventTracks,
  useCableNotifications
} from '../hooks'

import {get} from 'lodash'

import {connect} from 'react-redux'
// TODO:
// import { SearchPage } from './search'
import MediaPlayer from './media-player/MediaPlayer'

import { Header } from './header'
import { Alert, ScrollToTop, ThreeDotProgress } from './generics'
import '../styles/index.css'


// Detail pages
const BandDetailPage = lazy(() => import('./band/detail/BandDetailPage'))
const VenueDetailPage = lazy(() => import('./venue/detail/VenueDetailPage'))
const ArtistDetailPage = lazy(() => import('./artist/detail/ArtistDetailPage'))
const ProductDetailPage = lazy(() => import('./product/detail/ProductDetailPage'))
const PerformanceDetailPage = lazy(() => import('./performance/detail/PerformanceDetailPage'))

// Sign in / Registration pages
const RegistrationPage = lazy(() => import('./registration/RegistrationPage'))
const PasswordResetPage = lazy(() => import('./registration/PasswordResetPage'))
const EmailConfirmationPage = lazy(() => import('./registration/EmailConfirmationPage'))


// Admin pages

// Pages
const AdminPage = lazy(() => import('./admin/AdminPage'))
const ChatsPage = lazy(() => import('./messages/ChatsPage'))
const LegalPage = lazy(() => import('./legal/LegalPage'))
const LandingPage = lazy(() => import('./landing-page/LandingPage'))
const PageNotFound = lazy(() => import('./pages/PageNotFound'))
const SettingsPage = lazy(() => import('./settings/SettingsPage'))
const FavoritesPage = lazy(() => import('./favorites/FavoritesPage'))
const FollowingPage = lazy(() => import('./following/FollowingPage'))
const HomePageContainer = lazy(() => import('./home/HomePageContainer'))
const NotificationsPage = lazy(() => import('./notifications/NotificationsPage'))
const PerformanceWidgetPage = lazy(() => import('./widgets/PerformanceWidgetPage'))
const SpotifyAuthenticationPage = lazy(() => import('./pages/SpotifyAuthenticationPage'))


// Ecommerce
// ---------------------------------------------------------------------------------
// Merchant pages
const MerchantPage = lazy(() => import('./ecommerce/merchant/MerchantPage'))
const CheckoutPage = lazy(() => import('./ecommerce/checkout/CheckoutPage'))
const ReceiptPage = lazy(() => import('./ecommerce/receipt/ReceiptPage'))
const MerchantRegistrationPage = lazy(() => import('./ecommerce/merchant/registration/MerchantRegistrationPage'))
const PricingAndFeesPage = lazy(() => import('./ecommerce/notices/PricingAndFeesPage'))
const MerchantTipPage = lazy(() => import('./ecommerce/merchant/tips/MerchantTipPage'))


// User pages
const UserOrdersPage = lazy(() => import('./ecommerce/user/UserOrdersPage'))
// ---------------------------------------------------------------------------------



smoothscroll.polyfill()


const ignoreInitializationPaths = [
  '/user/confirm-email', 
  '/user/update-email', 
  '/reset-password',
  '/sign-up',
  '/sign-in',
  '/legal',
  '/page-not-found'
]



const shouldAppInitialize = path => {
  if (path === '/') return false

  for (let i = 0; i < ignoreInitializationPaths.length; i++) {
    if (path.startsWith(ignoreInitializationPaths[i])) return false
  }

  return true
}




const jsonLdData = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  "url": "https://www.ckord.com/",
  "name": "Ckord - Discover Live Music Events",
  "description": "Discover live music events near you, track your favorite artists, and get instant alerts about the best upcoming concerts with Ckord app.",
}




const App = props => {

  const location = useLocation()
  

  const {
    lat, 
    lng,
    accountId,
    isLocated,
    accountType,
    isAuthenticated,
    fetchAuthStatus, 
    fetchUserLocation, 
    setUserLocation,
    fetchFavoritesOfType,
    performanceSearchCount,
    fetchArtistRelationships,
    geolocationMethod,
    tryNextGeolocationMethod,
    updateLocationFilter,
    initiateSearch
  } = props


  const isInitializationPath = shouldAppInitialize(location.pathname)



  // Get user authentication status
  useEffect(() => {
    if (isAuthenticated === null && isInitializationPath) {
      fetchAuthStatus().catch(error => {})
    }
  }, [isAuthenticated, isInitializationPath, fetchAuthStatus])



  // Connect ActionCable notificaitons
  useCableNotifications()

  // Save in session storage where the user is coming from
  useUTMParams()


  // Finds the user's location by using three different methods:
  // - local storage
  // - navigator
  // - ipapi
  useEffect(() => {
    if (!isInitializationPath) return

    // First check if location is saved in local storage
    if (!geolocationMethod) {
      const latitude  = parseFloat(window.localStorage.getItem('lat'))
      const longitude = parseFloat(window.localStorage.getItem('lng'))

      let locatedOn = new Date()

      if (window.localStorage.getItem('locatedOn')) {
        locatedOn = Date.parse(window.localStorage.getItem('locatedOn'))
      }

      const hoursElapsed = Math.abs(new Date() - locatedOn) / 3600000


      if (latitude && longitude && hoursElapsed < 1) {
        setUserLocation(latitude, longitude, 'localStorage')
      } else {
        tryNextGeolocationMethod('navigator')
      }

    // Next try the navigator 
    } else if (geolocationMethod === 'navigator') {

      var ua = navigator.userAgent || navigator.vendor || window.opera

      if ((ua.indexOf('Instagram') > -1) || (ua.indexOf("FBAV") > -1)) {
        tryNextGeolocationMethod('ipapi')
        return
      }

      fetchUserLocation().then(response => {
        window.localStorage.setItem('lat', response.latitude)
        window.localStorage.setItem('lng', response.longitude)
        window.localStorage.setItem('locatedOn', new Date())
      })
      .catch(error => tryNextGeolocationMethod('ipapi'))

    // Last, try using ipapi
    } else if (geolocationMethod === 'ipapi') {
      axios.get('https://ipapi.co/json/').then(response => {
        const latitude  = response.data.latitude
        const longitude = response.data.longitude

        if (latitude && longitude) {
          setUserLocation(latitude, longitude, 'ipapi')
        } else {
          setUserLocation(40.7128, -74.0060, 'default')
        }
      })
      .catch(error => setUserLocation(40.7128, -74.0060, 'default'))
    }
  }, [
    setUserLocation, 
    geolocationMethod, 
    fetchUserLocation,
    isInitializationPath,
    tryNextGeolocationMethod
  ])



  // Event search for entire app (Map page and search bars)
  useSearchPerformances()

  // Venue search for entire app (Map page and search bars)
  useSearchVenues()

  // Fetch and audio tracks from the performers and the lsited events
  useSearchEventTracks()



  // Initial fetch
  useEffect(() => {
    if (performanceSearchCount > 0) {
      return 
    }

    if (isInitializationPath && isLocated) {
      updateLocationFilter({lat, lng}, 'Location - venue, address, city...', 1)
      initiateSearch()
    }
  }, [
    lat,
    lng,
    isLocated,
    initiateSearch,
    geolocationMethod,
    updateLocationFilter,
    isInitializationPath,
    performanceSearchCount
  ])




  useEffect(() => {
    if (isInitializationPath && isAuthenticated) {
      fetchFavoritesOfType('performances')
    }
  }, [isAuthenticated, isInitializationPath, fetchFavoritesOfType])



  useEffect(() => {
    if (accountType === 'artist' && accountId) {
      fetchArtistRelationships(accountId)
    }
  }, [accountId, accountType, fetchArtistRelationships])



  useEffect(() => {
    window.addEventListener('beforeunload', cleanup)


    return () => {
      window.removeEventListener('beforeunload', cleanup)
    }
  })




  // Any cleanup function that needs to happen before app closes
  function cleanup() {}


  return (
    <>
      <Helmet>
        <meta name='apple-itunes-app' content={'app-id=1575293616, app-argument=' + window.location.href} />

        {/*Basic Metadata */}
        <meta charset='UTF-8' />
        <meta name='viewport' content='minimum-scale=1,initial-scale=1,width=device-width,shrink-to-fit=no' />
        <meta name='theme-color' content='#000000'/>

        <title>Live Music | Live Shows App | Live Music Near Me | Ckord</title>
        <meta name='description' content='Discover live music events near you, track your favorite artists, and get instant alerts about the best upcoming concerts with Ckord app.' />
            

        {/* Advanced Metadata */}
        <meta http-equiv='X-UA-Compatible' content='IE=edge' /> 
        <meta name='robots' content='index,follow' />
        <meta name='revisit-after' content='7 days' />
        <link rel='canonical' href='https://www.ckord.com' />

        {/* Social Media Metadata (Open Graph and Twitter) */}

        {/* Open Graph */}
        <meta property='og:title' content='Live Music | Live Shows App | Live Music Near Me | Ckord' />
        <meta property='og:description' content='Discover live music events near you, track your favorite artists, and get instant alerts about the best upcoming concerts with Ckord app.' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.ckord.com/' /> 
        <meta property='og:image' content='https://s3.amazonaws.com/ckord.brand/ckord-logo-white.png' />
        <meta property='og:image:alt' content='Ckord Logo' /> 
        <meta property='og:image:width' content='480' />
        <meta property='og:image:height' content='507' />

        {/* Twitter */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Live Music | Live Shows App | Live Music Near Me | Ckord' />
        <meta name='twitter:description' content='Discover live music events near you, track your favorite artists, and get instant alerts about the best upcoming concerts with Ckord app.' />
        <meta name='twitter:image' content='https://s3.amazonaws.com/ckord.brand/ckord-logo-white.png' />
      

        <script type='application/ld+json'>{JSON.stringify(jsonLdData)}</script>
      </Helmet>

      <Header />
      <ScrollToTop />

      <Suspense fallback={<ThreeDotProgress position='absolute' radius={10} background={'transparent'} />}>
        <Routes>

          <Route path='/' exact element={<LandingPage />} />

          {['/sign-in', '/sign-up', '/reset-password'].map((path, index) => (
            <Route key={index} path={path} exact element={<RegistrationPage />} />
          ))}

          <Route path='/home' exact element={ <HomePageContainer /> } />
          <Route path='/bands/:id' exact element={ <BandDetailPage /> } />
          <Route path='/artists/:id' exact element={ <ArtistDetailPage /> } />
          <Route path='/venues/:id' exact element={ <VenueDetailPage /> } />
          <Route path='/products/:id' exact element={ <ProductDetailPage /> } />
          <Route path='/performances/:id' exact element={ <PerformanceDetailPage /> } />
          <Route path='/reset-password/:token' exact element={ <PasswordResetPage /> } />
          <Route path='/checkout' exact element={ <CheckoutPage /> } />
          <Route path='/checkout/complete' exact element={ <ReceiptPage /> } />


          {
            [
              '/merchants/registration',
              '/merchants/:type/:id/registration',
              '/merchants/:type/:id/registration/reauth',
              '/merchants/:type/:id/registration/return'
            ]
            .map((path, index) => (
              <Route key={index} path={path} exact element={
                <RequireAuth>
                  <MerchantRegistrationPage /> 
                </RequireAuth>
              } />
            ))
          }


          {['/shop'].map((path, index) => (
            <Route key={index} path={path} exact element={ 
              <RequireAuth>
                <MerchantPage />
              </RequireAuth> 
            } />
          ))}


          <Route path='/contributions' exact element={
            <RequireAuth>
              <MerchantTipPage />
            </RequireAuth>
          } />


          <Route path='/orders' exact element={
            <RequireAuth>
              <UserOrdersPage />
            </RequireAuth> 
          } />


          {['/legal/:content', '/legal', '/legal/mobile/:content'].map((path, index) => (
              <Route key={index} path={path} exact element={<LegalPage />} />
          ))}

          
          <Route path='/user/confirm-email/:token' exact element={ 
            <EmailConfirmationPage type='confirm' /> 
          } />

          <Route path='/user/update-email/:token' exact element={
            <RequireAuth>
              <EmailConfirmationPage type='update' />
            </RequireAuth>
          } />

          <Route path='/favorites' element={
            <RequireAuth>
              <FavoritesPage />
            </RequireAuth>
          } />

          <Route path='/following' element={
            <RequireAuth>
              <FollowingPage />
            </RequireAuth>
          } />

          <Route path='/messages' element={
            <RequireAuth>
              <ChatsPage />
            </RequireAuth>
          } />


          { ['/notifications', '/notifications/:type/:id'].map((path, index) => {
              return (
                <Route 
                  key={ index }
                  path={ path }
                  exact 
                  element={ 
                    <RequireAuth>
                      <NotificationsPage />
                    </RequireAuth>
                  } 
                />
              )
            })
          }

          { ['/settings', '/settings/:setting'].map((path, index) => {
              return (
                <Route 
                  key={ index }
                  path={ path }
                  exact 
                  element={ 
                    <RequireAuth>
                      <SettingsPage />
                    </RequireAuth>
                  } 
                />
              )
            })
          }


          <Route path='/fees' exact element={
            <PricingAndFeesPage />
          } />


          <Route path='/spotify-callback' exact element={
            <SpotifyAuthenticationPage />
          } />


          <Route path='/widget' exact element={
            <RequireAuth>
              <PerformanceWidgetPage />
            </RequireAuth>
          } />

          <Route path='/admin' exact element={
            <RequireAuth>
              <AdminPage />
            </RequireAuth>
          } />

          <Route path='*' element={ <PageNotFound /> } />

        </Routes>
      </Suspense>

      <Alert/>
      <MediaPlayer/>
    </>
  )
}




const mapStateToProps = (state, props) => {
  // console.log(state)

  return {
    isAuthenticated: state.user.isAuthenticated,
    accountId: get(state, 'user.account.id', null),
    accountType: get(state, 'user.account.type', null),
    performanceSearchCount: state.search.counter.performance,
    isLocated: state.user.location.isLocated,
    geolocationMethod: state.user.location.method,
    lat: get(state, 'user.location.lat'),
    lng: get(state, 'user.location.lng'),
  }
}




const actions = { 
  fetchAuthStatus,
  fetchFavoritesOfType,
  fetchUserLocation,
  setUserLocation,
  fetchArtistRelationships,
  tryNextGeolocationMethod,
  updateLocationFilter,
  initiateSearch
}


export default connect(mapStateToProps, actions)(App)



