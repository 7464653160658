import parsePhoneNumber from 'libphonenumber-js'


import {getTopAddress, getBottomAddress} from '../utils'


export function createVenueInfoWindow(venue) {

  const {
    tags,
    name, 
    address, 
    phoneNumber,
    image,
    imageDerivatives
  } = venue.attributes


  const top = getTopAddress(address)
  const bottom = getBottomAddress(address)



  const imageContent = (() => {
    const infoWindowImage = imageDerivatives.small || image

    if (infoWindowImage) {
      return '<img class="image" src="' + infoWindowImage + '">'
    } else {
      return (
        `<svg height="70" width="70" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path fill="#A9A9A9" d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/>
        </svg>`
      )
    }
  })()



  const phoneContent = (() => {
    if (phoneNumber) {

      const number = phoneNumber?.charAt(0) === '+' ? phoneNumber : '+1' + phoneNumber

      const parsed = parsePhoneNumber(number)

      if (parsed && parsed?.getURI() && parsed?.formatInternational()) {
        return (
          '<div class="section-container">' +
            '<a id="phone-link" href="' + parsed?.getURI() + '">' + parsed?.formatInternational() + '</a>' +
          '</div>' 
        )
      } else {
        return ''
      }
    } else {
      return ''
    }
  })()


  const tagContent = tags.reduce((acc, tag) => {
    acc += '#' + tag.toUpperCase() + ' '
    
    return acc
  }, '')




  const content = 
    '<div id="map-info-window-content" class="map-venue-info-window" data-type="venue" data-id="' + venue.id + '">' +
        '<div class="image-container">' +
          '<div class="aspect-ratio-box">' +
            '<div class="aspect-ratio-box-inside">' +
              imageContent +
            '</div>' +
          '</div>' +
        '</div>' +
        
        '<div class="details-container">' +
          '<div class="header">' +
            '<p>venue</p>' +
            '<h3>' + name + '</h3>' +
          '</div>' +

          '<div class="section-container">' +
            '<div class="address-rows-container">' +
              '<p class="address-row">' + top + '</p>' +
              '<p class="address-row">' + bottom + '</p>' +
            '</div>' +
          '</div>' +

          phoneContent +

          '<div class="tags-container">' +
            tagContent +
          '</div>' +
        '</div>' +
    '</div>'


  
  return new window.google.maps.InfoWindow({content: content})
}




