import { css } from '@emotion/react'

export const cardListMobileStyles = () => (
  {
    root: css`
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      &::-webkit-scrollbar {
        width: 1px; 
        display: none;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    `
  }
)



export default cardListMobileStyles