import React from 'react'
/** @jsxImportSource @emotion/react */
import { getImageOfSize } from '../../utils'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { day, dayOfMonth, month } from '../../utils'
import PerformanceCardButtons from './PerformanceCardButtons'
import Typography from '@mui/material/Typography'
import Image from '../generics/Image'


import performanceCardStyles from'../../styles/cards/desktop/performanceCardStyles'
import performanceCardMobileStyles from'../../styles/cards/mobile/performanceCardMobileStyles'


const isEqual = (prevProps, nextProps) => {
  const prevAttributes = prevProps.performance.attributes
  const nextAttributes = nextProps.performance.attributes

  const prevTime = (typeof prevAttributes.start === 'string') ? prevAttributes.start : prevAttributes.start.getTime()
  const nextTime = (typeof nextAttributes.start === 'string') ? nextAttributes.start : nextAttributes.start.getTime()


  return (
    prevTime === nextTime &&
    prevProps.editPerformance === nextProps.editPerformance &&
    prevProps.showStatsForPerformanceWithId === nextProps.showStatsForPerformanceWithId &&
    prevProps.deletePerformanceWithId === nextProps.deletePerformanceWithId &&
    prevAttributes.name === nextAttributes.name &&
    prevAttributes.permission === nextAttributes.permission &&
    prevAttributes.image === nextAttributes.image &&
    prevAttributes.address.city === nextAttributes.address.city &&
    prevAttributes.address.state === nextAttributes.address.state
  )
}



const PerformanceCard = props => {
  const navigate = useNavigate()
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const theme = useTheme()
  const { 
    performance, 
    editPerformanceWithId, 
    showStatsForPerformanceWithId,
    deletePerformanceWithId
  } = props
  const { start, name, permission, address, image } = performance?.attributes || {}
  const { city, state } = address || {}
  const imageURL = getImageOfSize(performance.attributes, 'medium') || image
  const href = process.env.REACT_APP_BASE_URL + '/performances/' + performance.id

  const styles = isExtraSmallScreen ? performanceCardMobileStyles({ theme })
                                    : performanceCardStyles({ theme })


  function handleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    navigate('/performances/' + performance.id)
  }



  const DateInfographic = () => (
    <div css={ styles.overlay }>
      <Typography css={ styles.dayAndMonth }>
        { day(start) }
      </Typography>

      <Typography css={ styles.day }>
        { dayOfMonth(start) }
      </Typography>

      <Typography css={ styles.dayAndMonth }>
        { month(start) }
      </Typography>
    </div> 
  )


  return (
    <div css={ styles.card }>
      { isExtraSmallScreen && <DateInfographic /> }

      <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
        <div css={ styles.imageContainer } onClick={ handleClick }>
          { !isExtraSmallScreen && <DateInfographic /> }

          <Image css={ styles.image } src={ imageURL } alt='performance' />
        </div>
      </a>

      <div css={ styles.infoContainer } onClick={ handleClick }>
        <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
          <Typography css={ styles.title }>
            { name }
          </Typography>

          <Typography noWrap>
            { city ? city : state }
          </Typography>
        </a>
      </div>

      <div css={ styles.buttons }>
        <PerformanceCardButtons
          performanceId={ performance.id } 
          name={ name } 
          permission={ permission }
          imageURL={ imageURL }
          editPerformanceWithId={ editPerformanceWithId }
          showStatsForPerformanceWithId={ showStatsForPerformanceWithId }
          deletePerformanceWithId={ deletePerformanceWithId }
        />
      </div>
    </div>
  )
}


export default React.memo(PerformanceCard, isEqual)

