import { format, parseISO } from 'date-fns'
import { isThisWeek as isThisWeekfns } from 'date-fns'


export function removeTimeZone(time) {
  return time.slice(0, -1)
}

export function formatDate(date, formatStyle = 'MMMM, do') {
  if (date) { 
    return format(parseISO(date.slice(0, -1)), formatStyle)
  }
}


export function formatTime(time, formatStyle = 'h:mm a') {
  if (time) {
    return format(parseISO(time.slice(0, -1)), formatStyle)
  }
}


export function formatMinutes(time) {
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.floor(time % 60)

  const secondsFormatted = seconds < 10 ? `0${seconds}` : seconds

  return `${minutes}:${secondsFormatted}`
}


export function day(date, formatStyle = 'eee') {
  return formatDate(date, formatStyle)
}


export function dayOfMonth(date, formatStyle = 'd') {
  return formatDate(date, formatStyle)
}


export function month(date, formatStyle = 'MMM') {
  return formatDate(date, formatStyle)
}


export function addHours(date, hours) {
  return new Date(date.getTime() + (hours*60*60*1000))
}


export function addYears(date, years) {
  return date.setFullYear(date.getFullYear() + 1)
}


export function isToday(d) {
  const today = new Date()
  const date = (d instanceof Date) ? d : new Date(d.slice(0, -1))

  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
}


export function isThisWeek(d) {
  const date = (d instanceof Date) ? d : new Date(d.slice(0, -1))

  return isThisWeekfns(date)
}


export function isThisYear(d) {
  const today = new Date()
  const date = (d instanceof Date) ? d : new Date(d.slice(0, -1))

  return date.getFullYear() === today.getFullYear()
}


export function convertUTCDateToLocalDate(date) {
  if (typeof date === 'string') {
    const utcDate = new Date(removeTimeZone(date))
    return UTCdateToLocal(utcDate)
  }
  if (date instanceof Date) {
    return UTCdateToLocal(date)
  }
  else {
    const now = new Date()
    const hour = now.getHours()
    const minutes = now.getMinutes()

    if (minutes <= 30) now.setMinutes(30)
    else {
      now.setHours(hour + 1)
      now.setMinutes(0)
    }
    
    return now
  }
}


export function formatFullDate(start, end, separator = '\u00A0\u00A0•\u00A0\u00A0') {
  const dayStr = day(start, 'eeee') + separator
  const dateStr = formatDate(start) + separator
  const timeStartStr = formatTime(start) + ' - '
  const timeEndStr = formatTime(end)
  const areDatesOnSameDay = datesAreOnSameDay(start, end)

  if (areDatesOnSameDay) {
    return dayStr + dateStr + timeStartStr + timeEndStr
  } else {
    return dayStr + dateStr + timeStartStr + timeEndStr
  }
}


export function datesAreOnSameDay(first, second) {
  first = convertUTCDateToLocalDate(first)
  second = convertUTCDateToLocalDate(second)

  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}


export function UTCdateToLocal(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  )
}


export function combineDateAndTime(date, time) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  )
}

export function dateToUTC(date) {
  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  ))
}



