import {icons} from '../helpers/google'


// Marker animation options: BOUNCE, DROP


export function createScaledIcon(url) {
  const isMobileDevice = window.innerWidth <= 800

  const width = isMobileDevice ? 27 : 32
  const height = isMobileDevice ? 35 : 40


  return {
    url: url,
    anchor: new window.google.maps.Point(16, 40),
    scaledSize: new window.google.maps.Size(width, height),
    flat: true
  }
}



export function createMapMarker(position, type) {
  const icon = icons[type]
  const zIndex = (type === 'venue' ? 5 : 7)
  const scaledIcon = createScaledIcon(icon)

  return new window.google.maps.Marker({
    zIndex, 
    position, 
    icon: scaledIcon,
    animation: window.google.maps.Animation.DROP,
    flat: true
  })
}