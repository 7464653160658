import React, { useState, useMemo } from 'react'
/** @jsxImportSource @emotion/react */
import { useIsMounted } from '../../hooks'
import TrackCard from './TrackCard'
import ArtistCard from './ArtistCard'
import BandCard from './BandCard'
import PerformanceCard from './PerformanceCard'
import VenueCard from './VenueCard'
import { Virtuoso } from 'react-virtuoso'
import { ThreeDotProgress } from '../generics'

import styled from '@emotion/styled'
import cardListMobileStyles from'../../styles/cards/mobile/cardListMobileStyles'



const PerformanceItemContainer = styled.div`
  width: 100%;
  height: 150px;
  box-sizing: border-box;
`

const ItemContainer = styled.div`
  height: 100px;
  width: 100%;
  box-sizing: border-box;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`



const isEqual = (prevProps, nextProps) => (
  prevProps.cards.length === nextProps.cards.length &&
  prevProps.hasNextPage === nextProps.hasNextPage
)


const CardListMobile = props => {
  const styles = cardListMobileStyles()
  const isMounted = useIsMounted()
  const { 
    type,
    cards, 
    fetchMoreItems, 
    hasNextPage
  } = props

  const [isFetching, setIsFetching] = useState(false)
  const itemCount = cards.length


  const Cards = useMemo(() => (
    cards.map(card => {
      switch (card.type) {
      case 'artist':
         return <ArtistCard artist={ card } />
      case 'band':
        return <BandCard band={ card } />
      case 'track':
        return <TrackCard track={ card } />
      case 'venue':
        return <VenueCard venue={ card } />
      case 'performance':
        return <PerformanceCard performance={ card } />
      default:
        return null
      }
    })
  ), [cards]) 




  const Footer = () => (
    <div
      style={{
        padding: '1rem',
        paddingBottom: '100px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      { isFetching && <ThreeDotProgress radius={ 4 } /> }
    </div>
  )



  return (
    <Virtuoso
      css={ styles.root }
      style={{ height: '100%', width: '100%' }}
      totalCount={ itemCount }
      fixedItemHeight={ type === 'performance' ? 150 : 100 }
      itemContent={ index => Cards[index]}
      computeItemKey={ index => cards[index].id }
      increaseViewportBy={{
        top: type === 'performance' ? 150 : 100,
        bottom: type === 'performance' ? 150 : 100
      }}
      useWindowScroll
      endReached={ index => {
        if (hasNextPage) {
          setIsFetching(true)
          fetchMoreItems({ index, setIsFetching, isMounted }) 
        }
      }}
      components={{
        Item: type === 'performance' ? PerformanceItemContainer : ItemContainer,
        List: ListContainer,
        Footer: hasNextPage ? Footer : null 
      }}
    />
  )
}

export default React.memo(CardListMobile, isEqual)


