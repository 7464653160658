import React, {useState, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {useLocation} from 'react-router-dom'

import parse from 'autosuggest-highlight/parse'

import {userCoordinatesSelector} from '../../../selectors'

import {usePlacePredictions, usePlaceDetails} from '../../../hooks'

import {updateLocationFilter, initiateSearch, showAlert, panTo} from '../../../actions'


import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'

import {HiLocationMarker} from 'react-icons/hi'




const cssStyles = props => ({
  startAdornment: css`
    margin: 0;
  `,
  iconButton: css`
    width: 40px;
    height: 40px;
    margin-left: 5px;
    margin-right: 5px;
  `,
  dropdown: css`
    margin-top: ${props.isHomePage ? '20px' : '5px'};
    padding-top: ${props.isHomePage ? '10px' : '10px'};
    padding-bottom: ${props.isHomePage ? '10px' : '10px'};
    min-height: 65px;
    width: ${props.isHomePage ? '400px' : null};
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    border-top-right-radius: 19px;
  `,
  textField: css`
    height: 100%;
    width: 100%;
  `,
  prediction: css`
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
  `,
  predictionAdornment: css`
    margin-right: 10px;
  `,
  caption: css`
    color: #7b7b7b;
    font-size: 15px;
    line-height: 1.2;
  `
})



const currentLocation = {
  id: 1,
  place_id: 'current_location',
  description: 'Current Location',
  structured_formatting: {
    main_text_matched_substrings: [],
    main_text: 'Current Location',
    secondary_text: 'Your current location'
  }
}




const LocationSearch = props => {

  const location = useLocation()

  const isHomePage = location.pathname === '/home'

  const styles = cssStyles({isHomePage})


  const {
    name,
    panTo,
    showAlert,
    userCoordinates,
    isUserLocated,
    initiateSearch,
    updateLocationFilter
  } = props



  const [value, setValue] = useState(null)

  const [isFocused, setIsFocused] = useState(false)

  // Location search 
  const [placeId, setPlaceId] = useState()

  const [inputValue, setInputValue] = useState('')

  const [predictions] = usePlacePredictions(inputValue)

  const place = usePlaceDetails(placeId)

  const predictionsWithCurrentLocation = [currentLocation, ...predictions]
  // ---------------------------------------------------------------------



  useEffect(() => {
    if (place) {
      const id = place.place_id

      const name = place.displayName

      const lat = place.location.lat()
      const lng = place.location.lng()

      updateLocationFilter({lat, lng}, name, id)
      initiateSearch()

      panTo({lat, lng})

    }
  }, [
    panTo, 
    place, 
    initiateSearch, 
    updateLocationFilter
  ])




  function handleChange(_, newValue) {
    if (newValue && newValue.place_id) {
      const placeId = newValue.place_id

      // This handles if a user selects 'Current Location'
      if (placeId === 'current_location') {
        if (isUserLocated) {
          
          updateLocationFilter(userCoordinates, 'Current Location', 1)
          initiateSearch()
          panTo(userCoordinates)
        
        } else {

          const message = `Your location couldn't be found. If you denied access to your location, 
            you can grant access in your browser settings.`
          
          showAlert(message, 'info')
          return
        }

        // Because 'Current Location' does not have an actual placeId,
        // we only set the value and return
        setValue(newValue)
        return
      }


      setValue(newValue)
      setPlaceId(placeId)
    }
  }




  function handleKeyPress(e) {
    e.stopPropagation()

    if (e.key === 'Enter') {
      if (predictions[0]) {
        handleChange(null, predictions[0])
      }
    }
  }



  function handleInputChange(_, newInputValue) {
    if (newInputValue === 'Current Location' && !isUserLocated) {
      return
    }

    setInputValue(newInputValue)
  }



  function predictionText(prediction) {
    return typeof prediction === 'string' ? prediction : prediction.description
  }



  function isOptionEqualToValue(prediction, value) {
    return prediction.place_id === value.place_id
  }



  function focus() {
    setIsFocused(true)
  }


  function unfocus() {
    setIsFocused(false)
  }



  return (
    <Autocomplete
      freeSolo
      onFocus={focus}
      onBlur={unfocus}
      autoComplete
      sx={{
        width: '100%',
        height: '100%',
        '& .MuiAutocomplete-endAdornment': {
          marginRight: '10px'
        }
      }}
      value={value}
      inputValue={inputValue}
      includeInputInList
      filterSelectedOptions
      getOptionLabel={predictionText}
      filterOptions={option => option}
      options={predictionsWithCurrentLocation}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      onInputChange={handleInputChange}
      PaperComponent={params => <Paper {...params} css={styles.dropdown} />}
      ListboxProps={{style: {maxHeight: 700}}} 
      renderInput={params => (
        <TextField
          {...params}
          css={styles.textField}
          variant='standard'
          placeholder={name}
          InputProps={{
            ...params.InputProps,
            style: {height: '100%'},
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position='start' css={styles.startAdornment}>
                <IconButton css={styles.iconButton}>
                  <HiLocationMarker size={30} color={isFocused ? 'rgb(62, 166, 255)' : '#000'} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
      renderOption={(props, prediction, {selected}) => {
        const matches = prediction.structured_formatting.main_text_matched_substrings

        const parts = parse(
          prediction.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        )

        const isDisabled = (prediction.place_id === 'current_location' && !isUserLocated)


        return (
          <div {...props} style={{opacity: isDisabled ? 0.3 : 1}} css={styles.prediction}>
            <div css={styles.predictionAdornment}>
              <HiLocationMarker size={20} />
            </div>

            <div>
              {parts.map((part, index) => (
                <span key={index} style={{fontWeight: part.highlight ? 600 : 400}}>
                  {part.text}
                </span>
              ))}

              <p css={styles.caption}>
                {prediction.structured_formatting.secondary_text}
              </p>
            </div>
          </div>
        )
      }}
    />
  )
}



const mapStateToProps = state => {

  const coordinates = userCoordinatesSelector(state)

  return {
    name: state.search.filters.location.name,
    userCoordinates: coordinates,
    isUserLocated: state.user.location.isLocated
  }
}


const actions = {showAlert, updateLocationFilter, initiateSearch, panTo}


export default connect(mapStateToProps, actions)(LocationSearch)

