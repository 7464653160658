import React from 'react'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: 16
  }
}))


const CustomTextField = ({ 
  disableUnderline, 
  startAdornment,
  ...props 
}) => {
  const classes = useStyles()
  const inputProps = { startAdornment }
  if (disableUnderline) inputProps.disableUnderline = disableUnderline

  
  return (
    <TextField
      className={ classes.root }
      InputProps={{
        classes: {
          input: classes.input },
          ...inputProps
      }}
      InputLabelProps= {{
        className: classes.label,
        classes: { shrink: classes.shrink },
      }}
      { ...props }
    />
  )
}


export default CustomTextField