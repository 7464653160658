import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { fullURL } from '../../utils'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block'
  },
  link: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: 3 
  }
}))



const WebsiteLink = props => {
  const classes = useStyles()
  const { url, isMailLink } = props
  const href = isMailLink ? 'mailto:' + url + '?subject=User Message From Ckord' : fullURL(url)

  return (
    <div className={ classes.root }>
      <a
        className={ classes.link }
        target='_blank'
        rel='noopener'
        href={ href }>
        { url }
      </a>
    </div>
  )
}


export default WebsiteLink