import {useEffect, useRef, useCallback, useState, useMemo} from 'react'
/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'

import {connect, useDispatch} from 'react-redux'

import {useNavigate} from 'react-router-dom'

import {throttle} from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'

import {useFetchLFFForTypeAndId} from '../../hooks'

import {show} from '../../apis'


import {
  extractArtistID,
  convertSpotifyTracksToCkordFormat
} from '../../helpers'


import {
  index, 
  play, 
  pause, 
  next, 
  previous, 
  close, 
  enqueue, 
  showAlert,
  addTracksToPerformance,
  replaceSpotifyTrackInQueue
} from '../../actions'


// Desktop devices
import MediaPlayerCompact from './MediaPlayerCompact'
import MediaPlayerExpanded from './MediaPlayerExpanded'

// Mobile devices
import MediaPlayerMobile from './MediaPlayerMobile'





const slide = keyframes`
  from {
    transform: translateY(100%);
  },

  to {
    transform: translateY(0%);
  }
`



const styles = {
  root: css`
    max-width: 306px;
    max-height: 544px;
  `,
  mobile: css`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    animation: ${slide} 300ms ease;
  `
}








const MediaPlayer = props => {

  const imageRef = useRef()
  const audioRef = useRef()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // Is audio ready to be played
  const [isReady, setIsReady] = useState(false)

  const [volume, setVolume] = useState(70)

  const [isMuted, setIsMuted] = useState(false)

  const [isCompact, setIsCompact] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)

  const [isFetching, setIsFetching] = useState(false)



  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))



  const { 
    play,
    next,
    queue,
    index,
    pause,
    close,
    links,
    isOpen,
    enqueue,
    position,
    previous,
    showAlert,
    isPlayRequested,
    addTracksToPerformance,
    replaceSpotifyTrackInQueue
  } = props


  const track = useMemo(() => queue[position] || {}, [queue, position])
  const queuedTrack = useMemo(() => queue[position + 1], [queue, position])


  const {
    image, 
    audio,
    isVideoTrack,
    isSpotifyTrack, 
    imageDerivatives
  } = track.attributes || {}




  const hasNextPage = (links.pages !== links.page)

  // Don't fetch if this track is a favorite
  const ignoreFavoriteFetch = track.link || isSpotifyTrack || isVideoTrack

  useFetchLFFForTypeAndId('track', track.id, ignoreFavoriteFetch)




  const fetchMoreTracks = useCallback(props => {
    if (hasNextPage) {
      const url = links.next_url.slice(4)

      index(url, 'TRACKS').then(response => {
        const links = response.links || {}

        enqueue(response.data, null, links)
      })
    }
  }, [index, enqueue, hasNextPage, links.next_url])





  useEffect(() => {
    if (audio) {
      audioRef.current?.load()
    }
  }, [audio])




  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = isMuted
    }
  }, [isMuted])



  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100
    }
  }, [volume])



  // This handles instances where the track is a Spotify link and 
  // requires the fetch of the track to replace the temp link track.
  // Important to note, the link is the id of the track.
  useEffect(() => {
    if (isFetching) return


    function fetchTrack(t) {
      const link = t.link
      const eventID = t.attributes.composerId
      const artistID = extractArtistID(link)
      const url = '/services/spotify/artists/' + artistID + '/tracks'

      const navigateTo = '/performances/' + eventID


      setIsFetching(true)


      show(url).then(response => {
        const data = response.data

        const tracks = convertSpotifyTracksToCkordFormat(data, eventID, 'performance', link)


        addTracksToPerformance(eventID, tracks)
        replaceSpotifyTrackInQueue(link, tracks, navigateTo)

        setIsFetching(false)
      })
      .catch(error => {
        dispatch({type: 'REMOVE_SPOTIFY_LINK_IN_QUEUE', payload: link})
        setIsFetching(false)
      })
    }
    

    // If the track is a Spotify link then fetch the appropriate track
    if (track.link) {
      fetchTrack(track)
    }

    if (queuedTrack?.link) {
      fetchTrack(queuedTrack)
    }
  }, [
    track,
    dispatch,
    isFetching,
    queuedTrack,
    addTracksToPerformance,
    replaceSpotifyTrackInQueue
  ])





  useEffect(() => {
    if (hasNextPage && (queue.length - index === 2)) {
      fetchMoreTracks()
    }
  }, [links, hasNextPage, fetchMoreTracks, queue.length, index])




  // Handles instances where someone presses the forward or back button
  // for a new song. 
  useEffect(() => {    
    async function playAudio() {
      try {
        await audioRef.current?.play()
        setIsPlaying(true)
      } catch {
        return
      }
    }


    // Handles case where someone hit forward (or back) and the song is 
    // ready to be played and the player is in the playing state 
    if (isReady && isPlayRequested) {
      playAudio()
    // However if the player is in a paused state, then keep the player in a paused state
    } else if (!isPlayRequested && isPlaying) {
      audioRef.current?.pause()
      setIsPlaying(false)
    }
  }, [isReady, isPlayRequested, isPlaying])





  useEffect(() => {
    // If this track is a video...
    if (isVideoTrack) {
      // If the player is minimized then don't play any videos. Instead go to the next song.
      if (isCompact) {
        next()
      // otherwise pause the current audio and play the video
      } else if (isPlaying) {
        audioRef.current?.pause()
      }
    }
  }, [track, next, isCompact, isVideoTrack, isPlaying])





  // If a user clicks on the composer information and its a Spotify track, 
  // then this will get the Ckord id and type of the composer so the user
  // can be taken to the detail page
  function fetchCkordIdAndType(track) {
    const url = '/services/spotify/artist-id/' + track.attributes.composerId

    return show(url).then(response => {
      const {id, type} = response.data.data || {}


      if (!id) {
        showAlert('This artist isn\'t listed on Ckord yet.', 'info')
        return false
      }

      navigate(`/${type.toLowerCase()}s/${id}`)
      return true
    })
  }




  function handleDownloadStarted() {
    setIsReady(false)
  }



  function handleCanPlay() {
    setIsReady(true)
  }



  function handleEnd() {
    next()
  }



  function handleError(e) {
    console.log(e)
  }


  
  function handlePlayClick(e) {
    e?.stopPropagation()
    isPlaying ? pause() : play()
  }





  const previousTrack = throttle((e) => {
    e?.stopPropagation()

    if (!isFetching) {
      previous()
    }
  }, [300])



  const nextTrack = throttle((e) => {
    e?.stopPropagation()
    
    if (!isFetching) {
      next()
    }
  }, [300])




  function minimizePlayer(e) {
    e?.stopPropagation()    
    setIsCompact(true)
  }



  function expandPlayer(e) {
    e?.stopPropagation()
    setIsCompact(false)
  }




  return (isOpen && track &&
    <div css={isExtraSmallScreen ? styles.mobile : styles.root}>
      {isExtraSmallScreen &&
        <MediaPlayerMobile
          track={track}
          imageRef={imageRef}
          audioRef={audioRef}
          isPlaying={isPlaying}
          volume={volume}
          setVolume={setVolume}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          handleEnd={handleEnd}
          playNextTrack={nextTrack}
          handleClose={close}
          handlePlayClick={handlePlayClick}
          playPreviousTrack={previousTrack}
          fetchCkordIdAndType={fetchCkordIdAndType}
        />
      }



      {!isExtraSmallScreen && isCompact &&
        <MediaPlayerCompact
          image={imageDerivatives?.small || image}
          isPlaying={isPlaying}
          handleClose={close}
          expandPlayer={expandPlayer}
          playNextTrack={nextTrack}
          playPreviousTrack={previousTrack}
          handlePlayClick={handlePlayClick}
          spotifyLink={track?.attributes?.spotifyLink}
          composerID={track?.attributes.composerId}
          composerType={track?.attributes.composerType}
        />
      }



      {!isExtraSmallScreen && !isCompact &&
        <MediaPlayerExpanded
          track={track}
          imageRef={imageRef}
          audioRef={audioRef}
          volume={volume}
          setVolume={setVolume}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          isPlaying={isPlaying}
          handleEnd={handleEnd}
          handleClose={close}
          minimizePlayer={minimizePlayer}
          playNextTrack={nextTrack}
          playPreviousTrack={previousTrack}
          handlePlayClick={handlePlayClick}
        />
      }
      


      <audio 
        preload='auto' 
        src={audio} 
        ref={audioRef}
        onEnded={handleEnd}
        onCanPlay={handleCanPlay} 
        onError={handleError}
        onLoadStart={handleDownloadStarted}
        onLoadedMetadata={handleCanPlay}
      />
    </div>
  )
}






const mapStateToProps = state => {
  
  const {
    queue,
    links, 
    isOpen, 
    position,
    isPlayRequested
  } = state.player


  return {
    links: links,
    queue: queue,
    isOpen: isOpen,
    position: position,
    isPlayRequested: isPlayRequested
  }
}



const actions = {
  play,
  next,
  close,
  index, 
  pause,
  enqueue,
  previous,
  showAlert,
  addTracksToPerformance,
  replaceSpotifyTrackInQueue
}



export default connect(mapStateToProps, actions)(MediaPlayer)


