import { 
  isToday, 
  isThisWeek, 
  isThisYear,
  formatTime,
  formatDate,
  getTopAddress,
  getBottomAddress,
} from '../utils'



export function createPerformanceMapInfoWindow(performance) {
  
  const {
    tags,
    name, 
    start, 
    image,
    venueId,
    address,
    location,
    trackCount,
    spotifyLinks,
    imageDerivatives 
  } = performance.attributes
  


  const top = getTopAddress(address)
  const bottom = getBottomAddress(address)



  const venueContent = (() => {
    if (venueId) { 
      return '<p id="venue-link" data-type="venue" data-id="' + venueId + '">' + location + '</p>'
    } else {
      return '<p class="venue">' + location + '</p>'
    }
  })()



  const imageContent = (() => {
    const infoWindowImage = imageDerivatives.small || image

    if (infoWindowImage) {
      return '<img class="image" src="' + infoWindowImage +'">'
    } else {
      return (
        `<svg height="70" width="70" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path fill="#A9A9A9" d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/>
        </svg>`
      )
    }
  })()



  const dateContent = (() => {
    if (isToday(start)) {
      return 'Today at ' + formatTime(start, 'p')
    } else if (isThisWeek(start)) {
      return formatDate(start, "eeee 'at' p")
    } else if (isThisYear(start)) {
      return formatDate(start, "eee, MMM d 'at' p")
    } else {
      return formatDate(start, "PP 'at' p")
    }
  })()



  const tagContent = tags.reduce((acc, tag) => {
    acc += '#' + tag.toUpperCase() + ' '
    
    return acc
  }, '')



  const playContent = (() => {
    // This captures which tracks need to be fetched if the user clicks the play button
    if (trackCount || spotifyLinks.length) {
      let dataValue = ''

      // If the performance has both Ckord tracks and Spotify links associated with it then fetch both...
      if (trackCount && spotifyLinks.length) {
        dataValue = 'both'
      // ...otherwise fetch the Ckord tracks...
      } else if (trackCount) {
        dataValue = 'tracks'
      // ...or Spotify tracks
      } else {
        dataValue = 'spotify'
      }

      return '<div id="play-button" class="play-button" data-id="' + performance.id + '" data-tracks="' + dataValue + '">▶︎</div>'
    }
    
    return ''
  })()




  const content =
    '<div id="map-info-window-content" class="map-performance-info-window" data-type="performance" data-id="' + performance.id + '">' +
      '<div class="image-container">' +
        '<div class="aspect-ratio-box">' +
          '<div class="aspect-ratio-box-inside">' +
            imageContent +
          '</div>' +
          playContent +
        '</div>' +
      '</div>' +

      
      '<div class="details-container">' +
        '<div class="header">' +
          '<span>event</span>' +
          '<h3>' + name + '</h3>' +
          '<p>' + dateContent + '</p>' +
        '</div>' +
        
        '<div class="section-container">' +
          '<div class="address-rows-container">' +
            venueContent +
            '<p class="address-row">' + top + '</p>' +
            '<p class="address-row">' + bottom + '</p>' +
          '</div>' +
        '</div>' +


        '<div class="tags-container">' +
          tagContent +
        '</div>' +
      '</div>' +
    '</div>'



  return new window.google.maps.InfoWindow({content: content})
}



