import venueMarker from '../images/VenueMarker.png'
import selectedMarker from '../images/SelectedMarker.png'
import performanceMarker from '../images/PerformanceMarker.png'


// Older version of Google Maps API
// export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap&loading=async`
export const GOOGLE_FONTS_URL = 'https://fonts.googleapis.com/css2'


// SVG verions of the venue, performance, and selected markers
// -------------------------------------------------------
// const venue = {
//   path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
//   fillColor: '#000',
//   fillOpacity: 1,
//   strokeWeight: 0,
//   anchor: {x: 12, y: 24},
//   scale: 2.5
// }


// const selected = {
//   path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
//   fillColor: '#14A800',
//   fillOpacity: 1,
//   strokeWeight: 1,
//   anchor: {x: 12, y: 24},
//   scale: 3
// }


// const performance = {
//   path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
//   fillColor: '#f24385',
//   fillOpacity: 1,
//   strokeWeight: 1,
//   anchor: {x: 12, y: 24},
//   scale: 2.5
// }
// -------------------------------------------------------
// 
//  User icon with no animation 
//  <svg width='60px' height='60px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
//   <circle cx='10' cy='10' r='4' stroke-width='0.5' stroke='%23d3d3d3' fill='rgba(255, 255, 255, 1)'/>
//   <circle cx='10' cy='10' r='2.5' stroke-width='1' fill='%23f24385' />
//  </svg>


 // User icon with animation 
 // `<svg width='60px' height='60px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
 //   <circle id='back' cx='10' cy='10' r='4' stroke-width='1' fill='rgba(255, 0, 85, 0.7)'>
 //     <animate
 //      attributeName='r'
 //      from='2.5'
 //      to='6'
 //      dur='1.5s'
 //      begin='0s'
 //      repeatCount='indefinite'
 //      fill='freeze' 
 //      id='back'
 //    />
 //    <animate 
 //      attributeType='CSS' attributeName='opacity'
 //      from='1'
 //      to='0'
 //      dur='1.5s'
 //      begin='0s'
 //      repeatCount='indefinite'
 //      fill='freeze' 
 //      id='back'
 //    />
 //  </circle>
 //  <circle class='front' cx='10' cy='10' r='3' fill='rgba(255, 0, 85, 0.9)' />
 // </svg>`



const user = (
 `<svg width='60px' height='60px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
   <circle id='back' cx='10' cy='10' r='4' stroke-width='1' fill='rgba(255, 0, 85, 0.7)'>
     <animate
      attributeName='r'
      from='2.5'
      to='6'
      dur='1.5s'
      begin='0s'
      repeatCount='indefinite'
      fill='freeze' 
      id='back'
    />
    <animate 
      attributeType='CSS' attributeName='opacity'
      from='1'
      to='0'
      dur='1.5s'
      begin='0s'
      repeatCount='indefinite'
      fill='freeze' 
      id='back'
    />
  </circle>
  <circle class='front' cx='10' cy='10' r='3' fill='rgba(255, 0, 85, 0.9)' />
 </svg>`
)



export const icons = {
  user, 
  venue: venueMarker, 
  selected: selectedMarker,
  performance: performanceMarker 
}

